// ***********************************
//import ReactGA from 'react-ga';
import {action,computed,observable} from 'mobx';
import {ViewContext,FetchContentType,DstOffset} from '../Types/enums';
import {IStores,IFetchPost,IScheduleSignature} from '../Types/interfaces';
import TimeLine from '../Scripts/timeline';
//import {HttpEncode} from '../Scripts/common';
// ***********************************
export default class Frame {
  //***********************
  private _parent: IStores;
  @observable private _initialized: boolean;
  @observable private _dates: TimeLine[];
  @observable private _dateIndexVar: number;
  @observable private _hourIndex: number;
  @observable private _renderRowCount: number;
  @observable private _dataRowCount: number;
  @observable private _lastRenderRow: number;
  @computed public get initialized(): boolean {return this._initialized;}
  @computed public get windowSize(): number {return this.getFrameSize();}
  @computed public get duration(): number {return this.windowSize*60;}
  @computed public get minDate(): TimeLine {return this._dates[0];}
  @computed public get maxDate(): TimeLine {return this._dates[14];}
  @computed public get boundDayFuture(): boolean {return this.dateIndex===14;}
  @computed public get boundDayPast(): boolean {return this.dateIndex===0;}
  @computed public get boundHourFuture(): boolean {return (this.boundDayFuture||this._parent.viewState.boundHours)&&(this.hourIndex===(24-this.windowSize));}
  @computed public get boundHourPast(): boolean {return (this.boundDayPast||this._parent.viewState.boundHours)&&(this.hourIndex===0);}
  @computed public get dates(): TimeLine[] {return this._dates;}
  @computed public get dateIndex(): number {return this._dateIndexVar;}
  public set dateIndex(value: number) {this.setDateIndex(value);}
  @computed public get hourIndex(): number {return this._hourIndex;}
  public set hourIndex(value: number) {this.setHourIndex(value);}
  @computed public get start(): TimeLine {return this.getStart();}
  @computed public get end(): TimeLine {return this.getEnd();}
  @computed public get renderRowCount(): number {return this._renderRowCount;}
  @computed public get lastRenderRow(): number {return this._lastRenderRow;}
  public set renderRowCount(value: number) {this._renderRowCount=value;}
  public set lastRenderRow(value: number) {this._lastRenderRow=value;}
  public get currentDate(): TimeLine {return this._dates[this.dateIndex];}
  public get windowDate(): TimeLine {
    let dt: TimeLine=new TimeLine(this._dates[this.dateIndex]);
    dt.hour=this.hourIndex;
    return dt;
  }
  //***********************
  constructor() {
    this._construct();
  }
  //***********************
  @action private _construct() {
    this._initialized=false;
  }
  //***********************

  @action public initialize(parent: IStores): void {
    this._parent=parent;
    this._initialized=true;
    this._renderRowCount=parent.siteSettings.channelsPerGridRow;
    this._dataRowCount=parent.siteSettings.channelsPerDataChunk;
    this.setDates();
  }
  // ***********************************
  @action public setDateIndex(index: number): void {
    if(index>this._dateIndexVar&&this.boundDayFuture) {return;}
    if(index<this._dateIndexVar&&this.boundDayPast) {return;}
    this._dateIndexVar=(index<15&&index>=0)? index:this._dateIndexVar;
  }
  public incrementDay(auto:boolean=false): void {
    this.dateIndex++;
    //if(!auto) {ReactGA.event({category: 'User Action',action: 'Advanced 1 Day'});}
  }
  public decrementDay(auto:boolean=false): void {
    this.dateIndex--;
    //if(!auto) {ReactGA.event({category: 'User Action',action: 'Reversed 1 Day'});}
  }
  //***********************
  @action public setHourIndex(index: number): void {
    // clip hours on the last day of the schedule
    //while(this.boundDayFuture&&index+this.windowSize>24) {index--;}

    if(index===24&&!this.boundHourFuture) {
      this.incrementDay(true);
      index=0;
    }
    if(index<0&&!this.boundHourPast) {
      this.decrementDay(true);
      index=23;
    }

    this._hourIndex=index;
  }
  //***********************
  public incrementHours(auto:boolean=false): void {
    this.hourIndex++;
    //if(!auto) {ReactGA.event({category: 'User Action',action: 'Advanced 1 hour'});}
  }
  public decrementHours(auto:boolean=false): void {
    this.hourIndex--;
    //if(!auto) {ReactGA.event({category: 'User Action',action: 'Reversed 1 Hour'});}
  }
  //***********************
  @action public now(): void {
    let currentTimeVal: number;
    if(this.initialized) {currentTimeVal=new TimeLine().valueOf()-this._parent.siteSettings.timeOffset;}
    else {currentTimeVal=new TimeLine().valueOf();}
    let date=new TimeLine(currentTimeVal);
    this.hourIndex=date.hour;
    this.dateIndex=1;
  }
  //***********************
  @action public primeTime(): void {
    let currentTimeVal: number;
    if(this.initialized) {currentTimeVal=new TimeLine().valueOf()-this._parent.siteSettings.timeOffset;}
    else {currentTimeVal=new TimeLine().valueOf();}
    let date=new TimeLine(currentTimeVal);
    this.hourIndex=date.hour;
    this.dateIndex=1;
  }
  //***********************
  @action public setEnd(itemEnd: number): void {
    switch(this._parent.viewState.viewMode) {
      default: case ViewContext.Standard:
        while(this.end.valueOf()<itemEnd) {this.incrementHours();}
        break;
      case ViewContext.Extended: case ViewContext.Daily:
        this.incrementHours();
        break;
    }
  }
  //***********************
  @action public setStart(itemStart: number): void {
    switch(this._parent.viewState.viewMode) {
      default: case ViewContext.Standard:
        while(this.start.valueOf()>itemStart) {this.decrementHours();}
        break;
      case ViewContext.Extended: case ViewContext.Daily:
        this.decrementHours();
        break;
    }
  }
  //***********************
  public modHours(offset: number,half: boolean): string {
    let dt=new TimeLine(this.windowDate);
    dt.hour+=offset;
    let hour=((dt.hour+11)%12+1);
    let mid: string=(dt.hour<12)? ' AM':' PM';
    return hour.toString()+':'+(half? '30':'00')+mid;
  }
  //***********************
  public modHoursOffset(offset: number,seed: DstOffset): DstOffset {
    if(seed!==DstOffset.None) {return seed;}
    if(offset<2||offset>3) {return DstOffset.None;}
    let dt=new TimeLine(this.windowDate);
    let dstOffSet=dt.timeZoneOffset;
    dt.hour+=offset;
    dstOffSet-=dt.timeZoneOffset;
    if(dstOffSet>0) {return DstOffset.Forward;}
    if(dstOffSet<0) {return DstOffset.Backward;}
    return DstOffset.None;
  }
  //***********************
  public isHourBound(hour: number): boolean {
    if(!this.boundDayFuture) {return false;}
    let num: number=hour+this.getFrameSize();
    if(num<25) {return false;}
    return true;
  }
  //***********************
  public getFrameSize(): number {
    let num=this._parent.siteSettings.maxGridHours;
    switch(this._parent.viewState!.mediaState) {
      case 1: case 2: case 3: return 1;
      case 4: return num<2? num:2;
      case 5: return num<3? num:3;
      case 6: return num<4? num:4;
      case 7: return num<5? num:5;
      case 8: default: return num<6? num:6;
    }
  }
  //***********************
  private setDates(): void {
    const {useCustomStartTime,customStartTime}=this._parent.siteSettings;
    this._dates=[];
    const startDay: TimeLine=this._parent.siteSettings.serverTime.shiftDay(-1);
    startDay.floorHour();
    this.hourIndex=useCustomStartTime? customStartTime.hour:startDay.hour;
    startDay.floor();
    for(var i=0;i<15;i++) {
      this._dates.push(new TimeLine(startDay));
      startDay.day+=1;
    }
    this.dateIndex=1;
  }
  //***********************
  private getStart(): TimeLine {
    let result=new TimeLine(this._dates[this.dateIndex].raw);
    result.hour=this.hourIndex;
    return result;
  }
  //***********************
  private getEnd(): TimeLine {
    let result=new TimeLine(this._dates[this.dateIndex].raw);
    result.hour=this.hourIndex;
    result.minute=result.minute+(this.windowSize*60);
    return result;
  }
  //***********************
  getRenderStart(): number {
    if(this.lastRenderRow>this.renderRowCount) {
      return this.lastRenderRow-this.renderRowCount;
    }
    return 0;
  }
  //***********************
  public fetchUrlGet(siteId: number,lineupId: string,usedChunks: string[]|null,channelIndex: number=-1): IScheduleSignature {
    let result: IScheduleSignature={} as IScheduleSignature;
    result.chunks=[];
    result.urls=[];
    if(this._parent.viewState.viewDetails) {
      let eventId: number=this._parent.details!.eventId;
      let chunkSig=`${siteId}-${lineupId}-${channelIndex}-${eventId}-details`
      if(!usedChunks||!usedChunks.find(val => val===chunkSig)) {
        result.urls.push(`data/event/${siteId}/${lineupId}/${channelIndex}/${eventId}`);
        result.chunks.push(chunkSig);
      }
      return result;
    }
    switch(this._parent.viewState.viewMode) {
      case ViewContext.Daily: {
        let minutesInDay: number=1440;
        let start: string=this.start.floored().serviceValue();
        channelIndex=this._parent.lineupInfo!.currentChannel.ChannelIndex;
        result.chunks.push();
        let chunkSig=`${siteId}-${lineupId}-${start}-${channelIndex}-daily`;
        if(!usedChunks||!usedChunks.find(val => val===chunkSig)) {
          result.urls.push(`data/events/${siteId}/${lineupId}/${start}/${minutesInDay}/${channelIndex}/1`);
          result.chunks.push(chunkSig);
        }
        break;
      }
      case ViewContext.Extended: {
        let dur: number=this.windowSize*60;
        let start: string=this.start.shiftDay(0).serviceValue();
        channelIndex=this._parent.lineupInfo!.currentChannel.ChannelIndex;
        let chunkSig=`${siteId}-${lineupId}-${start}-${channelIndex}-extended`;
        if(!usedChunks||!usedChunks.find(val => val===chunkSig)) {
          result.urls.push(`data/events/${siteId}/${lineupId}/${start}/${dur}/${channelIndex}/14`);
          result.chunks.push(chunkSig);
        }
        break;
      }
      case ViewContext.SearchResults: break;
      default:
      case ViewContext.Standard: {
        let rowValues: number[]=[];
        for(let dataStartIndex=0;dataStartIndex<this._lastRenderRow;dataStartIndex+=this._dataRowCount) {
          let startIndex=this.getRenderStart();
          let lastDataRow=dataStartIndex+this._dataRowCount;
          if((startIndex>=dataStartIndex&&startIndex<lastDataRow)||(this.lastRenderRow>dataStartIndex&&dataStartIndex<lastDataRow)) {rowValues.push(dataStartIndex);}
        }
        for(let j=0;j<rowValues.length;j++) {
          channelIndex=this._parent.lineupInfo.channels[rowValues[j]].ChannelIndex;
          let nextUrl: string='';
          let chunkWidthMin=60; //1hr chunks
          let start: string='';
          for(let duration=-1;duration<=this.windowSize;duration++) {
            if(nextUrl.length===0) {start=this.start.serviceValueHourOffset(duration);}
            const chanLen=this._parent.lineupInfo.channels.length;
            let channelCount: number=this._dataRowCount<chanLen? this._dataRowCount:chanLen;
            let chunkSig=`${siteId}-${lineupId}-${this.start.serviceValueHourOffset(duration)}-${channelIndex}-standard`
            if(!usedChunks||!(usedChunks.find(val => val===chunkSig))) {
              nextUrl=`data/eventspage/${siteId}/${lineupId}/${start}/${chunkWidthMin}/${channelIndex}/${channelCount}`
              chunkWidthMin+=60;
              result.chunks.push(chunkSig);
            }
            else {
              if(nextUrl.length>0) {
                result.urls.push(nextUrl);
                nextUrl='';
                chunkWidthMin=60;
              }
            }
          }
          if(nextUrl.length>0) {result.urls.push(nextUrl);}
        }
        break;
      }
    }
    return result;
  }
  //***********************
  public fetchUrlPost(siteId: number,lineupId: string,channel: number=-1): IFetchPost {
    let result={} as IFetchPost;
    switch(this._parent.viewState.viewMode) {
      default: break;
      case ViewContext.Standard: break;
      case ViewContext.Daily: break;
      case ViewContext.Extended: break;
      case ViewContext.SearchResults: {
        let searchValue=this._parent.viewState.searchValue.replace(/\/|\\/g,' ');
        result.url=`data/search/${siteId}/${lineupId}`;
        //result.body=JSON.stringify({searchText: searchValue});
        result.body=JSON.stringify({SearchText: searchValue,TitleOnly: this._parent.viewState.titleOnly });
        result.contentType=FetchContentType.Json;
        break;
      }
    }
    return result;

    
  }
  //***********************
}
// ***********************************
