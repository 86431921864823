// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../../Types/interfaces';
import CheckBoxVector from '../../SVG/checkbox';
// ***********************************
interface IProps extends IStoresInject {checked: boolean; label: string; onClick: () => void;}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class CheckBox extends React.Component<IProps,{}> {
  //***********************
  @observable hover: boolean;
  id: string;
  //***********************
  constructor(props: IProps) {
    super(props);
    const {viewState}=this.props;
    this._construct();
    this.id='checkBox-'+viewState!.uniqueIndexer.toString();
  }
  //***********************
  @action private _construct(){
    this.hover=false;
  }
  //***********************
  @action handleMouseEnter=(): void => {this.hover=true;};
  @action handleMouseLeave=(): void => {this.hover=false;};
  //***********************
  render() {
    const {masterStyles,proto,label,onClick,checked, as}=this.props;

    let baseStyle={...masterStyles!.inlineMiddle};
    const spanStyle={...masterStyles!.noSelect,fontSize: '.9em',paddingLeft: '3px',position: as!.relative,bottom: '2px'};
    return (
      <div style={baseStyle} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={onClick}>
        <CheckBoxVector color={this.hover? proto!.colorHover:proto!.colorControl} width={14} checked={checked} />
        <span style={spanStyle}>{label}</span>
      </div>
    );
  }
}