// ***********************************
import * as React from 'react';
import {observable,action} from 'mobx';
import {observer,inject} from 'mobx-react';
import {IStores,IStoresInject} from '../../Types/interfaces';
import OurSocialMedia from '../Controls/OurSocialMedia/oursocialmedia';
// ***********************************
interface IProps extends IStoresInject {}
// ***********************************
@inject((stores: IStores): IStoresInject => ({...stores}))
@observer export default class Footer extends React.Component<IProps,{}> {
  //***********************
  @observable ttvLinkHover: boolean;
  @observable tosLinkHover: boolean;
  @observable privacyLinkHover: boolean;
  @observable doNotSellLinkHover: boolean;
  @observable currentYear: number;
  //***********************
  constructor(props: IProps) {
    super(props);
    this._construct();
  }
  //***********************
  @action private _construct() {
    this.ttvLinkHover=false;
    this.currentYear=new Date().getFullYear();
  }
  //***********************
  @action ttvLinkEnter=(): void => {this.ttvLinkHover=true;};
  @action ttvLinkLeave=(): void => {this.ttvLinkHover=false;};
  //***********************
  @action tosLinkEnter=(): void => {this.tosLinkHover=true;};
  @action tosLinkLeave=(): void => {this.tosLinkHover=false;};
  //***********************
  @action privacyLinkEnter=(): void => {this.privacyLinkHover=true;};
  @action privacyLinkLeave=(): void => {this.privacyLinkHover=false;};
  //***********************
  @action doNotSellLinkEnter=(): void => {this.doNotSellLinkHover=true;};
  @action doNotSellLinkLeave=(): void => {this.doNotSellLinkHover=false;};
  //***********************
  render() {
    const {siteSettings,as,viewState}=this.props;
    const {isMobile}=viewState!;
    const baseTextCol='#C0C0C0';
    const hoverColor: string='#3090FF';
    if(!siteSettings!.loaded) {return (<div />);}
    const {proto}=this.props;
    let style={
      textAlign: as!.center,
      borderStyle: 'solid none none none',
      borderWidth: isMobile? '0':'1px',
      fontSize: '12px',
      color: baseTextCol,
      backgroundColor: '#454545',
      borderColor: proto!.colorBorder,
      flex:'0 0 auto'
    };
    let linkStyle={color: this.ttvLinkHover? hoverColor:'white',textDecoration: as!.none,fontWeight: as!.bold};
    let tosLinkStyle={color: this.tosLinkHover? hoverColor:'white',textDecoration: as!.none,fontWeight: 200};
    let privacyLinkStyle={color: this.privacyLinkHover? hoverColor:'white',textDecoration: as!.none,fontWeight: 200};
    let doNotSellLinkStyle={color: this.doNotSellLinkHover? hoverColor:'white',textDecoration: as!.none,fontWeight: 200};
    return (
      <div style={style}>
        <div style={{display:'inline-flex', flexDirection:as!.column, alignContent:'flex-start', padding:'10px 25px 5px 25px', verticalAlign:'middle' }}> {
          siteSettings!.branding.TitanTVFooterLink?
            <div>For complete television listings, visit
              <a style={linkStyle} onMouseEnter={this.ttvLinkEnter} onMouseLeave={this.ttvLinkLeave} href="https://www.titantv.com/">&nbsp;TitanTV.com</a>
            </div>
          :''
        }
          <div>© 2017-{this.currentYear} TitanTV, Inc.  All rights reserved.</div>
        </div>
        <div style={{display:'inline-block', padding:'10px 25px', verticalAlign:'middle'}}>
          {siteSettings!.branding.CorporateSocialMedia? <div>Follow TitanTV on social media.<hr/></div>:''}
          <OurSocialMedia spacerColor={baseTextCol} hoverColor={hoverColor} />
        </div><br />
        <button id="pmLink">Privacy Manager</button>
        <div/>
        <div style={{display:'inline-block', padding:'10px 25px', verticalAlign:'middle', fontSize:'10px', fontWeight:200}}>
          <a style={tosLinkStyle}  onMouseEnter={this.tosLinkEnter} onMouseLeave={this.tosLinkLeave} href="https://www.titantv.com/terms.aspx">Terms of Service</a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a style={privacyLinkStyle}  onMouseEnter={this.privacyLinkEnter} onMouseLeave={this.privacyLinkLeave} href="https://www.titantv.com/privacy.aspx">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;
          <a style={doNotSellLinkStyle} onMouseEnter={this.doNotSellLinkEnter} onMouseLeave={this.doNotSellLinkLeave} href="https://www.titantv.com/donotsell.aspx">Do Not Sell My Information</a>
        </div>
      </div>
    );
  }
  //***********************
}
// ***********************************